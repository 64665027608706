<template>
  <div class="templates">
    <Notification />
    <h1>Template</h1>
    <table id="template-table">
      <thead>
        <tr>          
          <th>Name</th>
          <th>Description</th>
          <th>Source</th>
          <th>Key</th>
          <th>Project</th>
          <th>Queue</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td> {{ template.name }}</td>
          <td> {{ template.description }} </td>
          <td> {{ template.source }}</td>
          <td> {{ template.key }}</td>
          <td><ProjectSelect @change="handleSelectProject"/></td>
          <td v-if="!templateQueuesLoading"><SelectList v-show="this.project!==null" :items="queues.map(q => q.name)" :hideMessage="true" :message="'Choose a Queue'" :selectedModel="template.queue" @filterList="handleSelectQueue" @change="validateTemplate"/></td>
          <td v-if="templateQueuesLoading"><font-awesome-icon :icon="['fas', 'spinner']"/></td>
        </tr>
      </tbody>
    </table>
    <hr/>
    <font-awesome-icon v-show="templateLoading" :icon="['fas', 'spinner']" class="loading-component"/>
    <div class="workflow-form">
      <form @submit.prevent="createWorkflowFromTemplate">
        <div class="row name-description">
          <div class="form-group">
            <label for="param-name">Workflow Name</label>
            <input type="text" v-model="template.workflow_name" name="param-name-input" class="form-control" @change="validateTemplate"/>
          </div>
          <div class="form-group">
            <label for="param-name">Workflow Description</label>
            <input type="text" v-model="template.workflow_description" name="param-name-input" class="form-control" @change="validateTemplate"/>
          </div>
        </div>
        <div v-if="template.params!==undefined">
          <h3>Workflow Parameters</h3>
          <div class="wf-parameters" v-for="param, index in template.params" :key="index">
            <div class="form-group">
              <label for="param-name">{{param.name}} {{param.description !== null? `(${param.description})` : ""}} </label>
              <input v-if="param.type!=='list'" type="text" v-model="param.value" name="param-default-input" class="form-control param-list" @change="validateTemplate"/>
              <textarea v-if="param.type==='list'" type="text" v-model="param.viewValue" name="param-default-input" class="list-input form-control" @change="validateTemplate"/>          
            </div>
          </div>
        </div>
        <hr/>
        <div>
          <div class="form-group" id="create-wf-btn" v-if="workflowValid">
            <button class="btn btn-primary app-button primary-button" type="submit">Create Workflow</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Notification from '../components/Generics/Notification.vue'
import SelectList from '../components/Generics/SelectList.vue'
import ProjectSelect from './ProjectSelect.vue'

export default {
  name: 'Template',
  components: { Notification, SelectList, ProjectSelect },
  props: {
    source: String,
    template_name: String,
  },
  computed: {
    ...mapGetters({
      template: 'getTemplate', 
      templateLoading: 'templateLoading',
      templateQueuesLoading: 'templateQueuesLoading',
      projects: "getProjects",
      queues: "getQueues",
      project: "getChosenProject"
    })
  },
  data(){
    return {
      workflowValid:false
    }
  },
  methods: {
    ...mapActions(['createWorkflowFromTemplate', 'loadTemplate', 'loadProjects', 'loadQueues', 'filterQueues', 'loadQueuesForTemplates']),
    async perpareTemplateAndSubmit(){
      await this.createWorkflowFromTemplate();
    },
    handleSelectQueue(input){
      this.template.queue = input
    },
    handleSelectProject(){
      this.loadQueuesForTemplates(this.project)
      this.validateTemplate()
    },  
    validateTemplate(){
      let params_derived = this.template.params !== undefined? this.template.params.map(p => p.type === "list" ? p.viewValue : p.value) : [undefined]
      if(this.project === undefined || 
        this.project === "" ||
        this.template.queue === undefined ||
        this.template.queue === "" || 
        this.template.workflow_name === undefined || 
        this.template.workflow_name === "" ||
        this.template.workflow_description === undefined || 
        this.template.workflow_description === "" || 
        params_derived.includes(undefined) || 
        params_derived.includes("")){
          this.workflowValid = false
      }
      else{
          this.workflowValid = true
      }
    }
  },
  mounted () {
    let args = {source: this.source, template_name: this.template_name}
    this.loadTemplate(args)
    if(this.project !== undefined){
      this.loadQueuesForTemplates(this.project)
    }
    this.validateTemplate()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#template-table, #template-params{
  width: 90%;
  margin: 1rem auto;
}

#create-wf-btn{
  display: flex;
  justify-content: center;
}

.loading-component {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
}

.name-description {
  display: flex;
}

.name-description input{
  width:15rem;
}

.name-description label{
  padding: 2rem;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin:1rem;
  padding: 0.25rem;;
}
.row input{
  width:15rem !important;
}

.param-list{
  padding: 2rem;
}

.list-input {
  width:100%;
  margin: 1rem auto;
  height:5rem;
  display: flex;
  justify-content: center;
}

.workflow-form{
  width:50%;
  margin: 1rem auto;
}

.templates{
  margin-bottom: 5rem;
}

</style>
